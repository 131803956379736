
import SaveButton from '../save-button.vue';

export default {
    name: 'EventExpensesModal',
    components: { SaveButton },
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            eventExpensesList: [
                {
                    eventExpenseType: null,
                    amount: null
                }
            ],
            expenseTypes: [],
            eventWorkingCopy: {}
        };
    },
    mounted() {
        this.$axios.get('/api/events/options').then(response => {
            this.expenseTypes = response.data.expenseTypes.map(expenseType => {
                expenseType['@id'] = `/api/event_expense_types/${expenseType.id}`;

                // Add sign based on isExpense
                expenseType.expenseTypeTitle = `${expenseType.expenseTypeTitle} ${expenseType.isExpense ? ' (expense)' : ' (revenue)'}`;

                return expenseType;
            });
        });
        this.eventWorkingCopy = JSON.parse(JSON.stringify(this.event));

        this.eventExpensesList = (this.eventWorkingCopy.eventExpenses || []).map(expense => {
            if (expense.eventExpenseType && expense.eventExpenseType.isExpense !== undefined) {
                expense.eventExpenseType.expenseTypeTitle = `${expense.eventExpenseType.expenseTypeTitle} ${expense.eventExpenseType.isExpense ? ' (expense)' : ' (revenue)'}`;
            }
            return expense;
        });


    },
    methods: {
        normalizeAmount(input) {
            if (typeof input !== 'string') input = String(input); // Ensure input is a string

            // Step 1: Remove spaces and invalid characters except numbers, dots, and commas
            input = input.replace(/[^\d.,-]/g, '');

            // Step 2: Identify whether the input uses ',' or '.' as the decimal separator
            const lastComma = input.lastIndexOf(',');
            const lastDot = input.lastIndexOf('.');

            if (lastComma > lastDot) {
                // If the last ',' appears after the last '.', assume ',' is the decimal separator
                input = input.replace(/\./g, ''); // Remove all thousand separators (.)
                input = input.replace(/,/, '.'); // Convert last ',' to '.'
            } else {
                // Otherwise, assume '.' is the decimal separator and remove ',' as thousand separators
                input = input.replace(/,/g, '');
            }

            // Step 3: Convert to number and ensure two decimal places
            const number = parseFloat(input);
            return isNaN(number) ? null : number.toFixed(2); // Return as a string with 2 decimal places
        },

        addExpense() {
            this.eventExpensesList.push({
                eventExpenseType: null,
                amount: null
            });
        },
        removeExpense(index) {
            this.eventExpensesList.splice(index, 1);
        },
        async submit() {

            this.loading = true;

            try {
                // Submit each expense and collect their IDs
                /* const expensesToCreate = this.eventExpensesList.map(expense => ({
                    eventExpenseType: expense.eventExpenseType['@id'] ? expense.eventExpenseType['@id'] : expense.eventExpenseType, // Reference to the EventExpenseType
                    amount: parseFloat(this.normalizeAmount(expense.amount)).toString()// Ensure amount is formatted as a string
                    event: this.eventWorkingCopy['@id']
                }));
                const expenseIds = [];
                for (const expense of expensesToCreate) {
                    const response = await this.$axios.post('/api/event_expenses', expense);
                    expenseIds.push(response.data['@id']);
                } */

                console.log('Data of EventExpenses', this.eventExpensesList);

                // Update the event object with the submitted expenses
                const finalEvent = {
                    eventExpenses: this.eventExpensesList.map(expense => ({
                        eventExpenseType: expense.eventExpenseType['@id'] ? expense.eventExpenseType['@id'] : expense.eventExpenseType, // Reference to the EventExpenseType
                        amount: parseFloat(this.normalizeAmount(expense.amount)).toString(), // Ensure amount is formatted as a string
                        event: this.eventWorkingCopy['@id']
                    }))
                };

                // Submit the updated event
                await this.$axios.patch(`/api/events/${this.eventWorkingCopy.id}`, finalEvent, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                }).then((response) => {
                    this.$emit('event-updated', response.data);

                    // Emit success and close the modal
                    this.$emit('event-updated');
                    this.$refs.modal.hide();
                }).finally(() => {
                    this.loading = false;
                });

            } catch (error) {
                console.error('Error submitting expenses or event:', error);
                this.$emit('error', error);
            } finally {
                this.loading = false;
            }
        }
    }
};
