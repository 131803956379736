
import CompaniesTable from '../company/companies-table';

export default {
    name: 'EventCompaniesCard',
    components: { CompaniesTable },
    props: {
        event: {
            type: Object,
            required: true
        },
        eventsUpdated: {
            type: Number,
            required: true
        }
    },
    methods: {
        eventCompaniesUpdated() {
            this.$emit('event-updated');
        }
    }
};
