
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import autolink from '../../mixins/autolink';
import modals from '../../mixins/modals';
import TableActions from '../table-actions';
import TableFilters from '../table-filters';
import ContactName from '../contacts/contact-name';
import GmapsAddress from '../gmaps-address';
import colors from '../../mixins/colors';
import fileDownload from '../../mixins/file-download';
import ConfirmModal from '../confirm-modal.vue';
import QuintableSearchInput from '../helpers/quintable-search-input.vue';
import CopyInformationModal from '../company/copy-information-modal.vue';
import { FEEDBACK_STATUS } from '../../mixins/feedback-status';
import RemoveFromEventModal from '../company/remove-from-event-modal';
import DownloadSelectColumnsModal from '../download-select-columns-modal.vue';
import EditEventModal from './edit-event-modal';
import EventConfirmModal from './event-confirm-modal';
import AddContactToEventModal from './event-component/add-contact-to-event-modal';
import EventCompanyTicker from './event-company-ticker.vue';
import eventBulkOperationModal from './event-bulk-operation-modal.vue';
import ImportFromSessionsModal from './import-from-sessions-modal.vue';
import optionButtonTooltip from '~/mixins/option-button-tooltip';


export default {
    name: 'EventsTable',
    components: {
        ImportFromSessionsModal,
        DownloadSelectColumnsModal,
        EventCompanyTicker,
        CopyInformationModal,
        AddContactToEventModal,
        QuintableSearchInput,
        ConfirmModal, GmapsAddress, EditEventModal,
        TableFilters, TableActions, ContactName, EventConfirmModal,
        flatPickr,
        eventBulkOperationModal,
        RemoveFromEventModal
    },
    mixins: [optionButtonTooltip, modals, autolink, colors, fileDownload],
    props: {
        showFilters: {
            type: Boolean,
            required: false,
            default: true
        },
        showDownload: {
            type: Boolean,
            required: false,
            default: true
        },
        companyId: {
            type: Number,
            default: null
        },
        companyHash: {
            type: String,
            default: null
        },
        pageSize: {
            type: Number,
            default: 25
        },
        showAttestationsDownload: {
            type: Boolean,
            default: false
        },
        showStatusSelectionRow: {
            type: Boolean,
            default: true
        },
        contactProfileConfiguration: {
            type: Boolean,
            default: false
        },
        onlyMyEvents: {
            type: Boolean,
            default: true
        },
        contact: {
            type: Object,
            default: null
        },
        feedbackStatus: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            eventLuciteId: null,
            eventId: null,
            isLoading: false,
            searchQuery: '',
            eventsUpdated: this.generateUUID(),
            modal: null,
            eventToEdit: null,
            filterOptions: {
                companies: [],
                participants: [],
                types: [],
                keyAccountManagers: [],
                teamMembers: [],
                departments: [],
                feedbackStatus: [],
                leadBankers: [],
                bankers: [],
                analysts: []
            },
            selectedCompanies: [],
            selectedParticipants: [],
            selectedTypes: [],
            selectedKeyAccountManagers: [],
            selectedTeamMembers: [],
            companyCharacteristics: [],
            companyGroups: [],
            onlyFavoriteCompanies: false,
            onlyOwnCompanies: false,
            showBlockers: false,
            upcoming: true,
            editEventModalKey: `editEventModalKey-${this.generateUUID()}`,
            selectedDepartments: [],
            selectedFeedbackStatus: null,
            selectedLeadBankers: [],
            selectedBankers: [],
            selectedAnalysts: [],
            excelSortOrder: [{
                index: 0,
                asc: false,
                headline: 'excelSort'
            }],
            eventToConfirm: {},
            toConfirm: true,
            toCancel: false,
            numberProposedEvents: 0,
            eventIdToRemove: null,
            activeStatus: this.contactProfileConfiguration ? 'Events' : 'Released',
            onlyMy: this.onlyMyEvents,
            selectedAttestationStatus: [],
            dates: null,
            flatpickrConfig: {
                mode: 'range',
                dateFormat: 'm/d/Y'
            },
            fromCompany: Boolean(this.companyId),
            ajaxRows: [],
            pageChanged: false,
            ajaxAll: 0,
            selectedRows: [],
            preSelectedRows: [],
            preSelectedRowIds: [],
            bulkTriggered: false,
            bulkUpdated: moment().unix(),
            companyIdsForInfo: null,
            eventIdForInfo: null,
            FEEDBACK_STATUS,
            eventIdToRemoveCompany: null,
            downloadQuery: null,
            importFromSessionsModalKey: 'importFromSessions'
        };
    },
    computed: {
        config() {
            const params = {
                highlightUpcoming: this.contactProfileConfiguration ? this.upcoming : ' ',
                fromCompany: this.fromCompany,
                showFeedbackSubmissionsCount: this.activeStatus === 'Past' || this.fromCompany,
                isContacts: this.isContacts
            };

            const myColumns = [
                {
                    headline: 'Date',
                    sort: true
                }, {
                    headline: 'Type',
                    sort: true,
                    breakpoint: 'sm'
                }, {
                    headline: '',
                    sort: false,
                    align: 'end',
                    breakpoint: 'sm'
                }, {
                    headline: 'Subject',
                    sort: true
                }, {
                    headline: 'Department',
                    sort: true,
                    breakpoint: 'lg'
                }, {
                    headline: 'Event Format',
                    sort: true,
                    breakpoint: 'lg'
                }, {
                    headline: 'Companies',
                    breakpoint: 'md'
                }, {
                    headline: 'Ticker',
                    breakpoint: 'xl'
                },
                {
                    headline: 'Investors',
                    hidden:
                        this.contactProfileConfiguration || (
                            this.showStatusSelectionRow &&
                            ['Pipeline', 'Declined', 'Proposed'].includes(this.activeStatus)
                        ),
                    breakpoint: 'xxl'
                }, {
                    headline: 'Meetings',
                    hidden:
                        this.contactProfileConfiguration || (
                            this.showStatusSelectionRow &&
                            ['Pipeline', 'Declined', 'Proposed'].includes(this.activeStatus)
                        ),
                    breakpoint: 'sm'
                }, {
                    headline: 'Event Goals',
                    hidden:
                        this.contactProfileConfiguration || (
                            this.showStatusSelectionRow &&
                            ['Pipeline', 'Declined', 'Proposed'].includes(this.activeStatus)
                        ),
                    breakpoint: this.activeStatus === 'Past' ? 'lg' : 'sm'
                }, {
                    headline: 'Feedback Sent',
                    hidden:
                        !this.isUserWithRoleCorporateAccess ||
                        this.contactProfileConfiguration ||
                        (this.showStatusSelectionRow && this.activeStatus !== 'Past'),
                    breakpoint: 'md'
                }, {
                    headline: 'Attestation Done',
                    hidden:
                        !this.isUserWithRoleCorporateAccess ||
                        this.contactProfileConfiguration ||
                        (this.showStatusSelectionRow && this.activeStatus !== 'Past'),
                    breakpoint: 'md'
                },
                {
                    headline: 'Proponent',
                    hidden: !this.showStatusSelectionRow || this.activeStatus !== 'Proposed',
                    breakpoint: 'md'
                },
                {
                    headline: 'Key Account Manager',
                    breakpoint: this.contactProfileConfiguration ? 'all' : 'lg'
                },
                {
                    headline: 'Reciprocal Assignment',
                    breakpoint: (this.showStatusSelectionRow && ['Pipeline', 'Declined', 'Proposed'].includes(this.activeStatus)) ? 'lg' : 'all'
                }, {
                    headline: 'Reciprocal Assignment Department',
                    breakpoint: 'all'
                },
                {
                    headline: 'Team',
                    hidden:
                        this.contactProfileConfiguration || (
                            this.showStatusSelectionRow &&
                            ['Pipeline', 'Declined', 'Proposed'].includes(this.activeStatus)
                        ),
                    breakpoint: this.contactProfileConfiguration ? 'all' : 'xl'
                },
                {
                    headline: 'Participants from this Company',
                    breakpoint: 'all',
                    hidden: !this.fromCompany
                },
                {
                    headline: 'Last Change',
                    sort: true,
                    breakpoint: 'all'
                }, {
                    headline: 'Status',
                    sort: true,
                    hidden: this.showStatusSelectionRow
                }, {
                    headline: 'Actions',
                    hideHeadlineBreakpoint: 'all',
                    align: 'end'
                },
                {
                    headline: 'Timezone',
                    sort: true,
                    breakpoint: 'all'
                }, {
                    headline: 'Location',
                    sort: true,
                    breakpoint: 'all'
                }, {
                    headline: 'Reason',
                    sort: true,
                    breakpoint: 'all'
                }, {
                    headline: 'Overview',
                    sort: true,
                    breakpoint: 'all'
                },
                {
                    headline: 'Session Meeting Request',
                    breakpoint: 'all',
                    hidden: this.selectedParticipantIDs && this.selectedParticipantIDs.length !== 1
                },
                {
                    headline: 'Cancelation Reason',
                    breakpoint: 'all',
                    hidden: this.activeStatus !== 'Declined' && this.activeStatus !== 'Canceled'
                }
            ];

            return {
                columns: myColumns,
                pagination: this.pageSize,
                search: true,
                ajaxUrl: '/api/events/list?' + this.buildQueryString(params),
                select: this.isUserWithRoleCorporateAccess,
                prettySelect: true,
                selectPosition: 'pre',
                ajaxAll: null,
                bulkModal: null
            };
        },
        selectedCompanyIDs() {
            if (this.companyId) {
                const selectedCompanyIds = this.selectedCompanies.map(x => x.id);
                selectedCompanyIds.push(this.companyId);
                return selectedCompanyIds;
            } else {
                return this.selectedCompanies.map(x => x.id);
            }
        },
        selectedParticipantIDs() {
            if (this.contact && this.contact.id) {
                const selectedContactIds = this.selectedParticipants.map(x => x.id);
                selectedContactIds.push(this.contact.id);
                return selectedContactIds;
            } else {
                return this.selectedParticipants.map(x => x.id);

            }
        },
        selectedKeyAccountmanagerIDs() {
            return this.selectedKeyAccountManagers.map(x => x.id);
        },
        selectedTeamMemberIDs() {
            return this.selectedTeamMembers.map(x => x.id);
        },
        selectedCompanyGroupIDs() {
            return this.companyGroups.map(x => x.id);
        },
        userIdFinal() {
            return this.onlyOwnCompanies && this.userInfo ? this.userInfo.id : this.userId;
        },
        filters() {
            let selectedStatuses = null;
            let upcoming = null;
            if (this.showStatusSelectionRow || this.contactProfileConfiguration) {
                switch (this.activeStatus) {
                    case 'Event':
                        selectedStatuses = ['Released'];
                        break;
                    case 'Released':
                        selectedStatuses = ['Released'];
                        upcoming = true;
                        break;
                    case 'Past':
                        selectedStatuses = ['Released'];
                        upcoming = false;
                        break;
                    case 'Pipeline':
                        selectedStatuses = ['In Pipeline'];
                        break;
                    case 'Proposed':
                        selectedStatuses = ['Proposed'];
                        break;
                    case 'Declined':
                        selectedStatuses = ['Declined'];
                        break;
                    case 'Canceled':
                        selectedStatuses = ['Canceled'];
                        break;
                }
            }

            return {
                companies: this.selectedCompanyIDs,
                companyHash: this.companyHash,
                participants: this.selectedParticipantIDs,
                types: this.selectedTypes,
                keyAccountManagers: this.selectedKeyAccountmanagerIDs,
                teamMembers: this.selectedTeamMemberIDs,
                upcoming,
                statuses: selectedStatuses,
                onlyFavoriteCompanies: this.onlyFavoriteCompanies,
                showBlockers: this.showBlockers,
                userId: this.userIdFinal,
                departments: this.selectedDepartments,
                feedbackStatus: this.selectedFeedbackStatus,
                attestationStatus: this.selectedAttestationStatus,
                leadBankers: this.selectedLeadBankers,
                bankers: this.selectedBankers,
                analysts: this.selectedAnalysts,
                dateStart: this.dateStart,
                dateEnd: this.dateEnd,
                companyCharacteristics: this.companyCharacteristics,
                companyGroups: this.selectedCompanyGroupIDs
            };
        },
        sortOrder() {
            return [{
                index: 0,
                asc: this.showStatusSelectionRow && !['Past', 'Canceled', 'Declined'].includes(this.activeStatus)
            }];
        },
        dateStart() {
            if (!this.dates || typeof this.dates !== 'string' || this.dates.length === 0) {
                return null;
            }

            const [start] = this.dates.split(' to ');
            const date = new Date(start);

            return this.formatLocalDateIso(date);
        },
        dateEnd() {
            if (!this.dates || typeof this.dates !== 'string' || this.dates.length === 0) {
                return null;
            }

            const [start, end] = this.dates.split(' to ');
            const date = end ? new Date(end) : new Date(start);

            return this.formatLocalDateIso(date);
        },
        bulkFilters() {
            switch (this.mode) {
                case 'selected':
                    return {
                        ids: structuredClone(this.preSelectedRowIds)
                    };
                case 'page':
                    return {
                        ids: this.ajaxRows.map(function(row) {
                            return row.research_report_id;
                        })
                    };
                case 'filtered':
                default:
                    if (this.searchQuery) {
                        return { ...this.filters, 'search_term': this.searchQuery };
                    }
                    return this.filters;
            }
        }
    },
    watch: {
        selectedRows(rows) {
            if (!this.pageChanged && this.ajaxRows) {
                for (let i = 0; i < rows.length; i++) {
                    if (!this.preSelectedRowIds.includes(rows[i].event_id)) {
                        this.preSelectedRowIds.push(rows[i].event_id);
                    }
                }

                for (let j = 0; j < this.ajaxRows.length; j++) {
                    const id = this.ajaxRows[j].event_id;

                    const index = this.preSelectedRowIds.indexOf(id);

                    if (!rows.map(r => r.event_id).includes(id) && index !== -1) {
                        this.preSelectedRowIds.splice(index, 1);
                    }
                }
            }
        }
    },


    mounted() {
        this.initializeOptionButtonTooltips();
        if (this.showFilters) {
            this.loadFilterOptions();
        }
        this.loadNumberProposedEvents();
    },
    methods: {

        loadNumberProposedEvents() {
            if (this.isUserWithRoleCorporateAccess) {
                this.$axios.get('/api/events/number_proposed_events').then(response => {
                    this.numberProposedEvents = response.data.numberProposedEvents;
                });
            }
        },
        addContactToEvent() {
            this.isLoading = true;
            this.modal = this.openModal(this.$refs.addContactToEvent);
            this.isLoading = false;
        },
        updateSearchQuery(searchQuery) {
            this.searchQuery = searchQuery;
        },
        updateSortOrder(order) {
            this.excelSortOrder = order;
        },
        addEvent(isBlocker = false) {
            this.isLoading = true;
            this.eventToEdit = null;
            this.editEventModalKey = `editEventModalKey-${this.generateUUID()}`;
            let url = '/api/events/empty';
            if (isBlocker) {
                url += '?type=blocker';
            }
            this.$axios.get(url)
                .then(response => {
                    this.eventToEdit = response.data;
                    this.$nextTick(() => {
                        this.modal = this.openModal(this.$refs.editEventModal);
                        this.isLoading = false;
                    });
                });
        },
        async editEvent(eventID) {
            this.isLoading = true;
            this.eventToEdit = null;
            this.editEventModalKey = `editEventModalKey-${eventID}`;
            // Wait for modal to rerender
            await this.$nextTick();
            this.$axios.get(`/api/events/${eventID}`)
                .then(async (response) => {
                    this.eventToEdit = response.data;
                    await this.$nextTick();
                    this.modal = this.openModal(this.$refs.editEventModal);
                    this.isLoading = false;
                });
        },
        confirmOrRejectEvent(eventID, toConfirm, toCancel) {
            this.$axios.get(`/api/events/${eventID}`)
                .then(async (response) => {
                    this.eventToConfirm = response.data;
                    this.toConfirm = toConfirm;
                    this.toCancel = toCancel;
                    await this.$nextTick();
                    this.modal = this.openModal(this.$refs.eventConfirmModal);
                });
        },
        deleteEvent(eventID, hasInteractions) {
            if (hasInteractions) {
                alert('To remove this event, first manually delete the interactions associated with it.');
            }

            if (!hasInteractions && confirm('Are you sure to permanently delete this event for everybody?')) {
                this.$axios.delete(`/api/events/${eventID}`)
                    .then(() => {
                        // Change key
                        this.eventsUpdated = this.generateUUID();

                    });
            }
        },
        removeCompanyFromEvent(eventId) {
            this.eventIdToRemoveCompany = eventId;
            this.$nextTick(() => {
                this.modal = this.openModal(this.$refs.removeFromEventModal);
            });
        },
        removeParticipant(eventID) {
            this.eventIdToRemove = eventID;
            console.log(eventID);
            this.modal = this.openModal(this.$refs.confirmRemoveParticipantModal);
        },
        doRemoveParticipant() {
            this.$axios.post(`/api/events/remove_event_participant/${this.eventIdToRemove}/${this.contact.id}`)
                .then((res) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: res.data.status ? 'success' : 'warning',
                        title: res.data.status ? 'Contact Removed' : 'Contact not Removed',
                        message: res.data.message,
                        id: toastId
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .finally(() => {
                    this.modal = null;
                    this.eventsUpdated = this.generateUUID();
                });
        },
        cancelEventAction() {
            this.closeModal(this.modal);
            this.modal = null;
        },
        eventUpdated() {
            this.eventsUpdated = this.generateUUID();
            if (this.modal) {
                this.closeModal(this.modal);
            }
            this.loadNumberProposedEvents();
        },
        loadFilterOptions() {
            this.$axios.get('/api/events/filter_options?ts=1708616529')
                .then((response) => {
                    this.filterOptions = response.data;
                });
        },
        resetFilters() {
            this.eventsUpdated = this.generateUUID();

            this.activeStatus = 'Past';

            this.selectedCompanies = [];
            this.selectedParticipants = [];
            this.selectedTypes = [];
            this.selectedKeyAccountManagers = [];
            this.selectedTeamMembers = [];
            this.selectedLeadBankers = [];
            this.selectedBankers = [];
            this.selectedAnalysts = [];

            this.onlyFavoriteCompanies = false;
            this.onlyOwnCompanies = false;
            this.selectedAttestationStatus = [];
            this.showBlockers = false;
            this.selectedDepartments = [];
            this.selectedFeedbackStatus = [];
            this.dates = null;
            this.companyGroups = [];
        },
        downloadEvents() {
            this.downloadQuery = {
                search: this.searchQuery,
                filters: this.filters,
                sort: this.excelSortOrder,
                options: {
                    companyId: this.companyId
                }
            };

            this.openModal(this.$refs.downloadEventsModal);
        },
        bulkDownloadEvent(val) {
            this.closeModal(this.bulkModal);
            this.downloadQuery = val;
            this.openModal(this.$refs.downloadEventsModal);
        },
        downloadAttestations() {
            const query = {
                search: this.searchQuery,
                filters: this.filters,
                sort: this.excelSortOrder
            };

            this.saveQueryAndExport(query, '/api/events/attestations_export');
        },
        formatLocalDateIso(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        openCompanyEventInformationModal(companyId, eventId) {
            this.companyIdsForInfo = [companyId];
            this.eventIdForInfo = eventId;
            this.$nextTick(() => {
                this.openModal(this.$refs.copyCompanyInformationModal);
            });
        },
        onRowsUpdated(data) {
            this.pageChanged = false;
            if (data && data.rows && data.rows.length) {
                this.preSelectedRows = this.preSelectedRowIds.map(id => {
                    return {
                        key: 'event_id',
                        value: id
                    };
                });
            }

            this.ajaxRows = data.rows;
            this.ajaxAll = data.all;
            this.tableLoaded = true;
        },
        onPageChange() {
            this.pageChanged = true;
        },
        clearPreSelection() {
            this.preSelectedRowIds = [];
            this.preSelectedRows = [];
        },
        openBulkOperationModal(mode) {
            this.mode = mode;
            this.bulkUpdated = moment().unix();
            this.bulkTriggered = true;

            this.$nextTick(() => {
                this.bulkModal = this.openModal(this.$refs.eventBulkModal);
            });
        },
        onBulkSubmitted(doReload = false) {
            if (doReload) {
                this.tableUpdated = this.generateUUID();
            }
            this.closeModal(this.bulkModal);
        },
        setFeedbackStatus(status, eventId) {
            this.$axios.patch(`/api/event/${eventId}/feedback_status`, {
                id: eventId,
                feedbackStatus: status
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: 'success',
                    title: 'Feedback Status Updated',
                    message: 'The feedback status has been changed to ' + status,
                    id: toastId
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.eventsUpdated = this.generateUUID();
            });
        },
        setIsCompleted(isCompleted, eventId) {

            // eslint-disable-next-line max-len
            let confirmationMessage = 'Are you sure you want to mark this event as completed? Once confirmed, no further actions, such as the submission of a Feedback Memorandum, will be required.';
            if (!isCompleted) {
                // eslint-disable-next-line max-len
                confirmationMessage = 'Are you sure you want to mark this event as not completed? Further actions, such as the submission of a Feedback Memorandum, will be required.';
            }

            if (!confirm(confirmationMessage)) {
                return;
            }

            this.$axios.patch(`/api/event/${eventId}/is_completed`, {
                id: eventId,
                isCompleted
            }, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: 'success',
                    title: 'Event Completed Updated',
                    message: `The event has been set to ${isCompleted ? 'completed' : 'not completed'}`,
                    id: toastId
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.eventsUpdated = this.generateUUID();
            });
        },
        async openSessionsImportModal() {
            this.importFromSessionsModalKey = this.generateUUID();
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.importSessionEventsModal);
        },
        onImportTriggered() {
            if (this.modal) {
                this.closeModal(this.modal);
                this.modal = null;
            }
        },
        syncSessions(e, cell) {
            e.stopPropagation();
            this.$axios
                .post(`/api/events/${cell.eventId}/sessions_clients_sync`)
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: 'success',
                        title: 'Sessions Clients/Participants Sync',
                        message: response.data.message,
                        id: toastId
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .then(() => {
                    this.$axios.post(`/api/events/${cell.eventId}/sync_sessions_meeting_requests`)
                        .then(() => {
                            const toastId = this.generateUUID();
                            this.addToast({
                                type: 'success',
                                title: 'Sessions Request Sync',
                                message: `Sessions Meeting Requests will be synced in background`,
                                id: toastId
                            });
                            this.$nextTick(() => {
                                this.toggleToast(toastId);
                            });
                        });
                });
        }
    }
};
